module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Dasa Genômica - Somos a medicina genômica da Dasa","short_name":"Dasa Genômica","start_url":"/","background_color":"#FFF","theme_color":"#000f40","display":"minimal-ui","icon":"src/assets/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"0d35122e7a09a122733612ec88174e19"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Home","exclude":[],"crumbLabelUpdates":[],"useClassNames":true,"usePathPrefix":"/AR"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"includeInDevelopment":false,"id":"GTM-P79ZPSM"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
